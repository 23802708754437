import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"


const SEO = ({ title, description }) => {
	const { pathname } = useLocation()
	const { site } = useStaticQuery(query)

	const {
		defaultTitle,
		titleTemplate,
		author,
		defaultDescription,
		siteUrl,
		keywords
	} = site.siteMetadata

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		url: `${siteUrl}${pathname}`,
	}

	return (
		<Helmet title={seo.title} titleTemplate={titleTemplate}>
			<html lang="it"/>
			<meta name="description" content={seo.description} />

			<meta name="theme-color" content="#f59e2e" />

			{seo.url && <meta property="og:url" content={seo.url} />}
			<meta property="og:type" content="website" />
			{seo.title && <meta property="og:title" content={seo.title} />}
			{seo.description && (
				<meta property="og:description" content={seo.description} />
			)}
			<meta name="keywords" content={keywords ? keywords.join(', ') : null} />
			<meta name="author" content={author} />
		</Helmet>
	)
}

export default SEO

const query = graphql`
query SEO {
	site {
		siteMetadata {
			defaultTitle: title
			titleTemplate
			author
			defaultDescription: description
			siteUrl: url
			keywords
		}
	}
}
`

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string
}

SEO.defaultProps = {
	title: null,
	description: null
}
